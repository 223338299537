import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Offerings from '../Offerings'
import PropTypes from 'prop-types'

const ProductsPageTemplate = ({
  heading,
  description,
  offerings,
  meta_title,
  meta_description,
}) => (
    <div>
      <Helmet>
        <title>{meta_title}</title>
        <meta name='description' content={meta_description} />
      </Helmet>
      <section className='section section--gradient'>
        <div className='container'>
          <div className='section'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='content'>
                  {/* <div className='navbar-menu' id='navMenu'>

                    <div className='navbar-end'>
                      <Link className='navbar-item' to='/about'>
                        Store Kita
  </Link>
                      <Link className='navbar-item' to='/blog'>
                        Blog
  </Link>
                      <Link className='navbar-item' to='/products'>
                        Produk Terbaru
  </Link>
                      <Link className='navbar-item' to='/pasar-baru'>
                        Tentang Pasar Baru
  </Link>
                      <div className='navbar-item'>
                        <div className='field is-grouped'>
                          <p className='control'>
                            <Link
                              className='button is-warning is-outlined'
                              to='/contact'>
                              Contact Us
        </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div>
                    <h3 className='has-text-weight-semibold is-size-2'>
                      {heading}
                    </h3>
                    <p>{description}</p>
                  </div>
                  <Offerings gridItems={offerings.blurbs} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )

ProductsPageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  offerings: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  testimonials: PropTypes.array,

}

export default ProductsPageTemplate
